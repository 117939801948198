@import url(https://fonts.googleapis.com/css2?family=Questrial&display=swap);
body, textarea, button {
  margin: 0;
  font-family: 'Questrial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fade {
  100%{ opacity: 1;}
  0%{ opacity: 0;}
}

.App {
  text-align: center;
}

.nav {
  background-color: whitesmoke;
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 50px;
  text-decoration: none;
  border-bottom: 1px solid #003049;
  z-index: 3;
  list-style: none;
  text-align: center;
  font-size: 18px;
  position: fixed;
}

.nav-danober {
  font-size: 23px;
  color: rgb(0, 0, 0);
  padding-top: 0px;
  padding-right: 30px;
  text-shadow: 2px 1px 15px rgba(71, 184, 255, 0.3);
}

.nav-left {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.nav-right {
  padding-right: 75px;
}

.nav a {
  display: inline-block;
  text-decoration: none;
  font-weight:900;
  padding: 26px;
  color: rgba(0, 0, 0, 0.6);
  transition: all 1.0s ease;
}

.home-body a:visited,
.about-body a:visited {
  color: yellowgreen;
}

.home-body a:hover,
.about-body a:hover {
  font-size: 30px;;
}

.about-resume {
  margin-bottom: 10vh;
}

.nav a:hover {
  transform: scale(1.04);
  transition: all 0.5s ease-in-out;
}

.nav a:focus {
  color:rgb(0, 120, 184);
  transform: scale(1.05);
  transition: all 0.7s ease;
  text-shadow: 2px 1px 15px rgb(0, 0, 0, 0.3);
}

.nav a:active {
  color:black;
}


.dan-ober-about-mobile-image {
  display: none;
}

.home-main-container,
.about-main-container,
.projects-main-container,
.contact-main-container {
  animation: fade 1s;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  margin-top: 15vh;
}

.layout {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.8);
  background-size: auto;
  width: 100%;
  min-height: 100vh;
}

.layout-left {
  color: whitesmoke;
  z-index: 1;
}

.layout-right {
  display: flex;
  flex-direction: row;
  height: 80vh;
  margin-top: 14vh;
  margin-left: 100vh;
  position: fixed;
  z-index: 0;
}

p {
  font-size: 22px;
}

a {
  color: tomato;
  text-decoration: none;
}

li {
  font-size: 25px;
}

h3 {
  font-size: 20px;
}

h5 {
  animation: fade infinite alternate-reverse 1.3s;
  font-size: 22px;
  text-shadow: 1.5px 1.5px rgb(98, 0, 255);
}

.home-header,
h2 {
  line-height: 0px;
  font-size: 30px;
  font-weight: 100;
  letter-spacing: 4px;
}

.about-header,
h2 {
  line-height: 0px;
  font-size: 30px;
  font-weight: 100;
  letter-spacing: 4px;
}

/* .home-header,
.about-header,
.projects-header,
.contact-header {
  position: fixed;
} */

.projects-top-container,
.about-left,
.home-main-container,
.contact-main-container {
  margin-left: 10vh;
  max-width: 100vh;
}

.projects-top-container {
  margin-bottom: 21vh;
}

.projects-stack-list {
  display: inline-flexbox;
}

.skills-icon {
  height: 68px;
  margin: 25px;
}

.projects-bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  background-color: rgb(0, 0, 0, 0.85);
}

.project-description {
  z-index: 5;
  text-shadow: 2px 1px 10px rgba(0, 0, 0);
}

.project-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 5vh;
  margin-bottom: 10vh;
}

.project-screenshots-container-mobile {
  display: none;
}

.project-logo-doberpop {
  height: 120px;
}

.project-logo-sunday-driver {
  height: 65px;
}

.project-logo-weathernest {
  height: 80px;
}

.project-logo-hot-chicken-heroes {
  height: 120px;
}

.project-logo-artist-abc {
  height: 100px;
}

.project-image {
  height: 60vh;
  z-index: 5;
  padding: 10vh;
}

.project-buttons-container {
  display: flex;
  flex-direction: row;
}

.project-button {
  margin: 5px 20px 0px 0px;
}

.project-details-container {
  margin: 3vh;
  margin-top: 15vh;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 50px;
}

label {
  padding-bottom: 8px;
}

input,
textarea {
  height: 30px;
  font-size: 16px;
  margin-bottom: 20px;
  border-radius: 6px;
  border-width: 1px;
  max-width: 660px;
  padding: 5px 10px;
}

textarea {
  height: 100px;
  font-size: 17px;
  padding: 10px 10px;
}

button {
  width: 145px;
  height: 45px;
  background-color: yellowgreen;
  font-size: 16px;
  margin-bottom: 20px;
  border-radius: 6px;
  border-width: 1px;
  font-weight: bold;
}

.footer {
  bottom: 0;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: whitesmoke;
  align-items: center;
  color: rgb(32, 75, 150);
  width: 100%;
  padding: 15px;
  z-index: 3;
}

.footer a {
  text-decoration: none;
  color: rgb(32, 75, 150);
}

.footer-links {
  display: flex;
  flex-direction: row;
}

.footer img {
  height: 25px;
  padding: 0px 25px 0px 5px;
}

/* Galaxy S10 */
@media only screen and (min-width: 360px) and (max-width: 767px) {
  .nav {
    padding: 2vh 2vh 1vh 1vh;
    height: 5vh;
  }


  .footer-links {
    margin-right: 4vh;
  }

  .nav-danober {
    display: none;
  }

  .nav a {
    text-decoration: none;
    padding: 8px;
  }

  .home-main-container,
  .about-main-container {
    margin: 12vh 5vh 5vh 5vh;
  }

  .about-left {
    margin: 0;
  }

  .about-resume {
    margin-bottom: 8vh;
  }
}

@media only screen and (min-width: 767px) and (max-width: 360px) {
  .nav {
    padding: 2vh 2vh 1vh 1vh;
    height: 5vh;
  }

  .nav-danober {
    display: none;
  }

  .nav a {
    text-decoration: none;
    padding: 8px;
  }


}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .nav {
    padding: 2vh 2vh 1vh 2vh;
    height: 5vh;
  }

  .nav-danober {
    display: none;
  }

  .nav a {
    text-decoration: none;
    padding: 8px;
  }

  .nav-right {
    padding-right: 55px;
  }

  .dan-ober {
    display: none;
  }

  .dan-ober-about-mobile-image {
    display: block;
    max-width: 40vh;
    justify-content: center;
  }

  .project-screenshots-container-mobile {
    display: flex;
    justify-content: center;
  }

  #project-image-doberpop {
    display: none;
  }

  #project-image-sunday-driver {
    display: none;
  }

  #project-image-weathernest {
    display: none;
  }

  #project-image-hot-chicken-heroes {
    display: none;
  }

  #project-image-artist-abc {
    display: none;
  }

  .home-main-container {
    margin: 12vh 4vh 0px 4vh;
  }

  .home-header,
  h2 {
    font-size: 30px;
    line-height: -1vh;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;
  }

  .home-body {
    font-size: 27px;
    margin-bottom: 15vh;
  }

  .home-main-container,
  a {
    text-align: center;
  }

  .about-main-container {
    margin: 12vh 4vh 8vh 4vh;
  }

  .about-header,
  h2 {
    font-size: 30px;
    line-height: -1vh;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;
    margin-bottom: 5vh;
  }

  .about-body {
    font-size: 25px;
    padding-top: 1px;
  }

  .about-left {
    margin: 0;
  }

  .form-container {
    padding-bottom: 0;
  }

  .form-container,
  .submit-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .submit-button {
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    margin-top: 1.25vh;
    height: 7vh;
    padding: 0 auto;
    padding-left: 41%;
    font-weight: 900;
  }

  .contact-main-container {
    margin: 12vh 4vh 8vh 4vh;
  }

  .contact-callout {
    font-size: 18px;
  }

  .about-learn-more,
  .about-resume {
    text-align: center;
    font-weight: 600;
    padding-top: 10px;
    font-size: 24px;
  }

  .projects-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    margin: 12vh 0vh 8vh 0vh;
  }

  .projects-top-container {
    margin: 0vh 4vh 19vh 4vh;
    text-align: center;
  }

  .projects-top-container li {
    list-style: none;
  }

  .projects-stack-list {
    padding:0;
  }

  .skills-icon {
    height: 55px;
    margin: 15px;
  }
  

  .projects-bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 50vh;
  }

  .project-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: auto;
    margin-top: 0vh;
    margin-bottom: 5vh;
    margin-left:auto;
    margin-right:auto;
  }

  .project-details-container {
    margin-top: 0vh;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }

  .project-logo-doberpop {
    height: 100px;
  }

  .project-logo-sunday-driver {
    height: 60px;
  }

  .project-logo-weathernest {
    height: 75px;
  }

  .project-logo-hot-chicken-heroes {
    height: 120px;
  }

  .project-logo-artist-abc {
    height: 100px;
  }

  .project-image {
    height: 28vh;
    z-index: 5;
    padding: 1vh;
  }

  .project-description {
    display: flex;
    text-align: center;
  }

  .skills-icon {
    height: 48px;
    margin: 15px;
  }
  

  .project-buttons-container {
    flex-direction: column;
    margin-top: 1vh;
  }

  .project-button {
    margin: 7px;
    width: 35vh;
    height: 6vh;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .nav {
    padding: 5vh;
    height: 6vh;
  }

  .nav a {
    margin: 0 20px 0 20px;
  }

  .nav-danober {
    margin: 0;
    display: flex;
    flex-direction: row;
    font-size: 23px;
    color: rgb(0, 0, 0);
    padding: 5px 50px 0px 0px;
    text-shadow: 2px 1px 15px rgba(71, 184, 255, 0.3);
  }

  .layout-right,
  .dan-ober {
    display: flex;
    flex-direction: row;
    height: 70vh;
    margin-top: 5vh;
    margin-left: 20vh;
    position: fixed;
    z-index: 0;
  }

  .home-main-container,
  .about-main-container,
  .contact-main-container {
    margin: 22vh 10vh 20vh 15vh;
    text-align: left;
  }

  .projects-bottom-container {
    min-width: 204vh;
  }

  .projects-top-container {
    margin: 10vh 10vh 20vh 15vh;;
  }

  .home-main-container,
  .about-main-container,
  .projects-main-container,
  .contact-main-container {
    text-align: left;
  }

  .home-header,
  .about-header,
  .projects-header,
  .contact-header,
  .about-learn-more,
  .about-resume {
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 5vh;
    text-align: left;
    font-size: 22px;
  }

  .about-learn-more,
  .about-resume {
    font-size: 18px;
  }

  .home-header {
    margin-bottom: 2vh;
  }

  .home-main-container,
  .home-body {
    margin-bottom: 0vh;
  }

  .home-body,
  p {
    font-size: 17px;
  }

  .about-resume p {
    text-align: center;
    font-weight: 600;
    padding-top: 10px;
    font-size: 20px;
  }

  .dan-ober-about-mobile-image {
    display: none;
  }

  .form-container,
  .home-body,
  .about-left {
    width: 100vh;
  }

  .footer {
    padding: 10px;
  }

  #project-image-sunday-driver {
    display: flex;
  }

  #project-image-hot-chicken-heroes {
    display: flex;
  }

  #project-image-doberpop {
    display: flex;
  }

  #project-image-artist-abc {
    display: flex;
  }

  .projects-top-container {
    margin-bottom: 10vh;
  }
  
  .projects-bottom-container {
    display: flex;
    flex-direction: column;
    /* padding-left:0px; */
    /* justify-content: space-evenly; */
    /* align-content: center; */
    background-color: rgb(0, 0, 0, 0.75);
    max-width: 195vh;
  }
  
  .project-description {
    z-index: 5;
    text-shadow: 2px 1px 10px rgba(0, 0, 0);
  }
  
  .project-container {
    margin-top: 5vh;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  .project-screenshots-container-mobile {
    display: none;
  }
  
  .project-logo-doberpop {
    height: 80px;
  }
  
  .project-logo-sunday-driver {
    height: 45px;
  }
  
  .project-logo-weathernest {
    height: 60px;
  }
  
  .project-logo-hot-chicken-heroes {
    height: 80px;
  }

  .project-logo-artist-abc {
    height: 60px;
  }
  
  .project-image {
    height: 60vh;
    z-index: 5;
    margin: 0px 30px 0px 30px;
  }

  .project-description {
    font-size: 16px;
    margin:5vh;
  }
  
  .project-buttons-container {
    display: flex;
    flex-direction: row;
  }
  
  .project-button {
    justify-content: space-evenly;
    height: 45px;
    width: 50vh;
  }
  
  .project-details-container {
    margin: 0vh;
    justify-content: space-around;
  }

  .projects-stack-list li {
    font-size: 14px;
    margin-left: 0vh;
  }

  .submit-button {
    margin-top: 1vh;
    height: 12vh;
    font-size: 16px;
    font-weight: 900;
    padding-left: 42%;
  }

  .layout-right {
    display: flex;
    flex-direction: row;
    height: 80vh;
    margin-top: 14vh;
    margin-left: 100vh;
    position: fixed;
    z-index: 0;
  }

  ul {
    padding-left:6vh;
  }

  input {
    font-size: 14px;
  }

  textarea {
    font-size: 15px;
  }

}
